.sort-list-radio-group {
  display: flex;
  flex-direction: column;
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: 1px solid white;
      background-color: var(--color-primary);
      &::after {
        background-color: var(--color-primary);
      }
    }
  }
}
.sort-list-title {
  font-size: 18px;
  font-weight: 600;
  color: #141f5d;
  padding: 16px 0;
}
.filter-title,
.clear-filters {
  display: none;
}

@media (max-width: 767px) {
  button.clear-filters {
    display: block;
    font-weight: 600;
    padding: 0;
    padding-bottom: 10px;
    margin-bottom: 14px;
    outline: none;
    box-shadow: none;
    border: none;
  }
  .filter-title {
    display: block;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-metalblue);
  }
  .ant-select.filter-select {
    display: flex;
    align-items: center;
    .ant-select-selector {
      color: #6c6c6c;
      font-weight: 500;
      border: 2px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      height: 50px !important;
      padding: 10px 16px !important;
    }
  }
}
