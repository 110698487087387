.masked-logo{
    background: linear-gradient(135deg, #f0abfc 0%, #fdba74 100%);
    width: 200px;
    height: 200px;
    -webkit-mask-image: url(https://d1mgntrf3vaj6d.cloudfront.net/logo/logo.svg);
    mask-image: url(https://d1mgntrf3vaj6d.cloudfront.net/logo/logo.svg);
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}