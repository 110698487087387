.page-logo-loader {
  position: absolute;
  z-index: 1000;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 20vw;
    margin-bottom: 5vh;
  }


}
