.course-landing-navigation{
    position: absolute;
    left: 2em;
    top: 2em;
    max-width: 15%;
    height: 95%;

    @media (min-width: 1480px) {
        min-width: 200px !important;
    }

    @media (min-width: 1180px) {
        min-width: 150px;
    }

    @media (max-width: 1080px) {
        left: 0.5em;
    }

    @media (max-width: 768px) {
        position: relative;
        left: 0;
        top: -1px;
        max-width: 100%;
    }

    &-links{
        position: sticky;
        top: 2em;
        background-color: #edf3f9;
        font-weight: 500;
        padding: 1em 0;
        border-radius: 0.5em;
        z-index: 20;

        p {
            margin: 0 0.5em;
            padding: 1em 0.5em;
            font-size: 14px;
            cursor: pointer;

            @media (max-width: 768px) and (min-width: 480px){
                margin: 0 1.5em;
            }

            &:hover{
                text-decoration: underline;
            }
        }

        .currentLink{
            font-weight: 600;
        }

        .course-landing-navigation-link{
            position: relative;
        }

        .outline {
            position: absolute;
            right: 0;
            top: 3px;
            width: 5px;
            height: 40px;
            display: block;
            background-color: var(--color-primary);
        }
    }
}