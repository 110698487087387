.course-reviews-summary {
  display: flex;
  align-items: center;

  p {
    margin: 12px;
    font-size: 16px;
    font-weight: 500;

    @media (max-width: 480px) {
      margin: 10px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      color: red;

      @media (max-width: 480px) {
        margin-right: 0;
      }

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .ant-rate-star {
    margin: 0 0.1em 0.2em;

    &:last-child {
      margin: 0 0.1em 0.2em;
    }
  }

  &-ratings {
    font-weight: 600 !important;

    .fa-star {
      color: #f4c550;
    }
  }

  .ant-rate {
    margin: 0 0.5em;
    font-size: 1.2em;
  }
}
