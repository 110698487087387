.error-boundary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 50vw;
  background-color: white;
  padding: 0.5em;
  margin: auto;
  margin-bottom: 1em;
  margin-top: 5%;
  &-warning-message {
    font-weight: 600;
    font-size: 1em;
    text-align: center;
  }
  &-warning-img {
    width: 75%;
    background: #fff;
  }
}
