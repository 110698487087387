.bead {
    border-width: 0.2rem;
    border-style: solid;
    clip-path: circle(50% at center);
    transition: background-color 0.2s;
}




.bead:not(:hover) .inner {
    animation: 0.2s moveout forwards ease-in;
}

.bead:active {
    transform: scale(1.1);

}

.bead:hover .inner {
    animation: 0.2s movein forwards ease-in;
}

@keyframes movein {
    0% {
        top: 150%;

    }

    100% {
        top: 0%;
    }
}

@keyframes moveout {
    0% {}

    100% {
        top: 150%;
    }
}