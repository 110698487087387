.course-landing-all-reviews-stats {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: #201850;
  color: #fff;
  border-radius: 1em;
  // margin: 2em 0.2em;
  flex: 0 0 30%;
  // height: 100%;
  min-height: 250px;

  h1 {
    font-size: 72px !important;
    color: #fff;
    margin: 0.5em !important;
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }
}
