.course-landing-topics {
  margin-top: 2em;


  &-module {
  margin-top: 2em;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;

    .course-landing-topics-module-title-h2 {
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      // background-color: #eaf3fb;
      .course-landing-topics-module-title-h2 {
        // text-decoration: underline;
        border-bottom: 2px solid black;
        transform: translateY(-2px);
      }
    }

    &-title {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &:hover {
        cursor: pointer;
      }

      p {
        font-size: 1.3em;
        font-weight: 500;
      }

      @media (max-width: 480px) {
        h2 {
          font-size: 1.3em !important;
        }

        p {
          font-size: 1em;
        }
      }

      div {
        display: flex;
        align-items: center;

        span.circle {
          margin-right: 1em;
          width: 2em;
          height: 2em;
          border-radius: 30px;
          background-color: #f4f9fe;
          border: 3px inset var(--color-grey);
          transition: all 0.25s linear;

          &.active {
            border-width: 0;
            background-color: var(--color-grey);
          }
        }

        h2 {
          font-size: 18px;
          font-weight: 600;
          margin: 0;
        }
      }
    }

    &-free,
    .fa-lock {
      margin: 0 1em;
    }

    &-free {
      background-color: rgba(41, 176, 98, 0.23);
      padding: 0.3em 1em;
      border-radius: 1em;
      color: #29b062;
      font-weight: 700;
      font-size: 12px;
    }

    .fa-lock {
      filter: contrast(0.1);
    }

    &-total-exercises {
      margin: 0.5em 0 1em 50px;

      @media (max-width: 480px) {
        font-size: 1em;
      }
    }
  }

  /* For mock data placeholder */
  .mock {
    .ant-collapse-header {
      display: flex;

      .skeleton {
        margin: 0;
      }

      @media (max-width: 768px) {
        .anticon {
          top: 50% !important;
        }
      }
    }
  }
}
