.study-plan-card-purchased {
  background-color: #fff;
  padding: 2em;
  border-radius: 1em;
  margin-bottom: 1em;

  button > i {
    display: none;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    &-info {
      width: 60%;
      h2 {
        font-size: 24px;
        font-weight: 500;
      }

      p {
        font-size: 16px;
      }
    }
  }

  &-blocks {
    display: flex;
    justify-content: space-between;
    margin: 2em 0 0;
    overflow: hidden;
  }

  &-block {
    margin: 0.5em 0.5em 1.5em;
    cursor: pointer;
    position: relative;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
      &:after {
        display: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 40px;
      height: 3px;
      background-color: #eceffa;
      right: -35px;
      top: 15px;
    }

    &.completed {
      &:after {
        background-color: #29af62;
      }
    }

    &:hover {
      p {
        text-decoration: underline;
      }
    }

    &-icon {
      margin: 0 auto;
      width: 33px;
      height: 33px;
      background-color: #eceffa;
      font-size: 16px;
      font-weight: 700;
      border-radius: 1em;
      color: #173286;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .allStepsCompleted {
      background: none !important;

      img {
        width: 33px;
        height: 33px;
      }
    }

    p {
      max-width: 75px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      word-wrap: break-word;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
    }
  }

  &-progress {
    width: 250px;
    display: flex;
    white-space: nowrap;

    span {
      margin-left: 1em;
    }
  }
}
