.study-plan-card-author {
  width: 10%;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  .avatar {
    margin-bottom: 1em;
    justify-content: center;
  }

  &-name {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 12px;
  }
}
