
.exerciseview-text {
  font-family: "Old Standard TT";
  padding: 24px 0;
  line-height: 1.2;
}

// TOOD: move to ExerciseViewToolbar.scss
.exercise-view-solution {
  &-right-icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
    color: rgba(2, 26, 53, 0.5);
    cursor: pointer;

    img,
    .fa {
      padding: 0 0.5em;
    }
  }
  &-mobile {
    display: flex;
    justify-content: space-between;
    padding-bottom: 6px;
    /* border-bottom: 1px solid rgba(49, 59, 113, 0.1); */
  }
}
