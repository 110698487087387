.stats-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  /* overflow: hidden; */
  position: relative;
  min-width: 200px;
  max-width: 750px;
  flex-wrap: wrap;

  &-box {
    @media (max-width: 968px) and (min-width: 769px) {
      flex: 2 0 44%;
    }
  }
}

.stats-card .value {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 3em;
  line-height: 1.5em;
  text-align: center;
  color: var(--color-primary);
}

.stats-card .name {
  font-family: Montserrat;
  font-size: 1.5em;
  text-align: center;
  color: var(--color-metalblue);
  font-weight: 500;
}

.stats-card-text {
  padding: 0.5em 1.5em;
}

.stats-card h3 {
  font-size: 2em;
  font-weight: 700;
}

.stats-card-text p {
  font-size: 1em;
  margin-bottom: 0;
}

.stats-card-text button {
  font-size: 1.5em;
  padding: 0;
  text-decoration: underline;
}

.stats-card-icon {
  padding: 0.5em;
  border-radius: 1000px;
  background-color: #afbfef;
  color: white;
  height: 200%;
  width: 120%;
  font-size: 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 2260px) {
  .stats-card {
    font-size: 14px;
  }
}

@media (max-width: 1460px) {
  .stats-card {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .stats-card {
    flex-wrap: wrap;
    width: 60%;
    margin: 0 auto;
  }
  .stats-card .m-2 {
    width: 35%;
  }
  .stats-card .value {
    font-size: 3em;
  }
  .stats-card .name {
    font-size: 1.5em;
  }
}
