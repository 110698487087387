.lading-page-schools-carrousel {
  overflow: hidden;
  position: relative;
}

.lading-page-schools-carrousel h1 {
  color: var(--color-metalblue);
  font-size: 3em;
  line-height: 1.2em;
  margin-bottom: 1em;
  line-height: 1.4em;
}

.lading-page-schools-carrousel img {
  height: 3.5em;
  /* width: 50%;  */
}

.lading-page-schools-carrousel p {
  font-size: 1.4em;
  line-height: 1.2;
}

.lading-page-schools-carrousel-image {
  height: 100% !important;
  width: 100%;
  /* bottom: 0; */
}

@media (max-width: 1460px) {
  .lading-page-schools-carrousel {
    font-size: 10px;
  }
}

@media (min-width: 769px) {
  .lading-page-schools-carrousel {
    margin-right: 5%;
    margin-top: -20vh;
    padding-left: 4%;
  }
}

@media (max-width: 768px) {
  .lading-page-schools-carrousel-image {
    display: block;
    width: 100%;
    height: auto !important;
  }
}
