.toggle {
  &-title {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    p {
      font-size: 1.3em;
      font-weight: 500;
    }

    @media (max-width: 480px) {
      h2 {
        font-size: 1.3em !important;
      }

      p {
        font-size: 1em;
      }
    }

    div {
      display: flex;
      align-items: center;

      span.circle {
        margin-right: 1em;
        width: 2em;
        height: 2em;
        border-radius: 30px;
        background-color: transparent;
        border: 3px solid var(--color-grey);

        &.active {
          border: 3px solid var(--color-darkblue);
        }
      }

      h2 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }
    }
  }

  &-subtitle {
    margin: 0 0 1em 50px;


    @media (max-width: 480px) {
      font-size: 1em;
    }
  }

  &-children-container {
    padding-left: 2em;
    margin-left: calc(1em - 2px);
    position: relative;
    border-left: 2px solid var(--color-grey);
  }
}
