.account-subscription {
  h2 {
    font-size: 22px;
    margin-bottom: 1em;
    color: #5a5959;
    font-weight: 600;
    margin-top: 3em;
    &:first-child {
      margin-top: 0;
    }
  }

  &-status-panel {
    display: flex;
    align-items: center;

    & > div:first-child {
      flex-basis: 70%;
      overflow: hidden;
      @media (max-width: 768px) {
        flex-basis: 100%;
      }
    }

    & > div:last-child {
      flex-basis: 30%;
      margin-left: 1em;
      align-self: stretch;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}
