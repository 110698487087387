.course-landing-topic-details {

  margin-left: calc(1em - 2px);

  &.active {
    border-left: 2px solid var(--color-grey);

    &:before {
      display: none;
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 1em;
    height: 1em;

    border-color: var(--color-grey);
    border-style: solid;
    border-width: 0 0 2px 2px;
    border-bottom-left-radius: 8px;
  }

  // When only one module, variation in UI is in place
  &.soloModuleContainer {
    display: flex;
    flex-wrap: wrap;
    border-left: 0;
    padding-left: 0;
    margin-left: 0;

    .course-landing-topic-details-tag {
      flex: 45% 0;
      margin: 0.5em;

      &:empty {
        display: none;
      }

      .course-landing-topic-tag-container {
        margin-bottom: -1em;
        padding: 1.5em;

        .course-landing-topic-tag-index {
          display: none;
        }

        .course-landing-topic-tag-details {
          h3 {
            line-break: break-word;
            max-width: 50%;
          }
        }

        .course-landing-topic-tag-progress {
          flex-direction: column;
          align-items: flex-start;

          .progress-bar {
            max-width: 150px;
            margin: 0 0 1em !important;
          }
        }
      }
    }
  }

  &-tag {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #434c50;
    // position: relative;
    color: #b5b5b5;

    // Progress bar component
    .course-landing-topic-tag-progress-vals {
      color: #fff;
    }

    svg {
      filter: invert(0.75);
    }
  }

  .freeTopic {
    background-color: white;
    color: #000;
    cursor: pointer;

    h3 {
      color: #173286 !important;
    }

    .course-landing-topic-tag-progress-vals {
      color: #000;
    }

    svg {
      filter: invert(0);
    }
  }
}
