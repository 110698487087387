.course-price-tag {
  padding: 0.4em 1.2em;
  border-radius: 1em;
  font-weight: 700;
  font-size: 12px;
  width: fit-content;

  &-tooltip-link {
    color: var(--color-primary) !important;
    i:first-child {
      //   display: none
    }
  }
}

.ant-tooltip{
    
    &-inner {
        padding: 1em 1em 0.8em;
        border-radius: 0.5em;
        color: #000;
        background-color: #fff;
        font-size: 16px;
    }

    &-arrow {
        width: 25px;
        height: 25px;

        &-content{
            background-color: #fff;
            width: 20px;
            height: 20px;
        }
    }
}
