.exercise-evaluation {
  width: 100%;


  hr {
    width: 85%;
    margin: 0.5em auto;
    border-top: 1px solid #cfcfcf;
    border-bottom: none;
  }

  &-title {
    font-size: 1.2em;
    color: var(--color-blue);
    font-weight: 600;
    // display: flex;
    // justify-content: space-between;
  }

  &.mock {
    padding-bottom: 1.5em;

    .login-prompt {
      padding: 0.5em 1.5em;
      color: var(--color-primary) !important;
      font-weight: 600;
    }

    .login-prompt:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .exercise-evaluation-title {
      color: var(--color-grey);
    }

    .timer-toggle {
      display: none;
    }
  }
}
