.exercise-card {
  transition: opacity 0.2s ease-in-out;
  i {
    color: var(--color-grey);
  }


  &-metadata {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &.collapsed {
    .exercise-card-metadata {
      flex-direction: column;
    }
  }

  &-difficulty {
    //width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      align-items: center;

      i {
        font-size: 14px;
      }
    }
  }

  &-date-container {
    span {

      font-size: 14px;
    }
  }

  &-author {
    span {

      font-size: 12px;
      //max-width: 50%;
      white-space: pre;
      padding-right: 5px;
    }
  }
}

/** horizontal list view card design **/

.exercise-list.horizontal {
  .exercise-card {
    margin-right: 0.5rem;
    border-radius: 8px;

    .card-content {
      padding: 1em;
    }



    &-difficulty {
      i {
        margin-right: 0;
        font-size: 12px;
        margin-left: 1em;
      }
    }

    &-date-container {
      //white-space: nowrap;
      span {
        font-size: 12px;
      }
    }

    .info {
      display: none;
    }

    &-author {
      display: none;
    }
  }
}
