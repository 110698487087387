.review-card {
  max-width: 70vw;
  margin: auto;
}

.review-card .ant-score-text {
  color: var(--color-green);
  font-weight: 900;
  margin-left: 1em;
}
