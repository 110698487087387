.course-study-plan {
  padding: 2em 0;

  &-title {
    font-size: 2em;
    margin: 1em 0 -1em;

    @media (max-width: 768px) {
      margin: 1em 0 -1em 1em;
    }

    @media (max-width: 480px) {
      margin: 1em 0 -1em 0.5em;
    }
  }

  &-banner {
    display: flex;
    flex-direction: column;
    margin: 4em 0 1em;

    @media (max-width: 768px) {
      margin: 4em 2em 1em;
    }

    @media (max-width: 480px) {
      margin: 4em 1em 1em;
    }

    .study-plan-card {
      h2:first-child {
        font-size: 18px;
        font-weight: 500;
      }

      p {
        font-size: 12px;
      }
    }
  }
}
