.exercise-list {
  overflow: hidden visible;
  position: relative;

  /* padding: 0px 10px; */
  &.vertical {
    @media (min-width: 769px) {
      max-height: 75vh;
      overflow-y: scroll;
    }


  }

  &.horizontal {

    &:hover{
      .exercise-card{
        opacity: 1;
      }
    }

    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5em 0;
    padding-bottom: 0.5em;

    &.exercise-list::-webkit-scrollbar {
      height: 6px;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}

/* Let's get this party started */
.exercise-list::-webkit-scrollbar {
  width: 4px;

}

/* Track */
.exercise-list::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  //background: #eee;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-top: 3px;

  border-top-right-radius: 40px;
}

/* Handle */
.exercise-list::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--color-grey);
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

.exercise-list-header {
  padding: 1em;
  background-color: var(--color-grey);
  color: var(--color-text) !important;
  position: sticky;
  top: 0;
  z-index: 2;
}

.exercise-list-cell {
  vertical-align: middle;
  white-space: nowrap;
  text-align: left;
  max-width: 200px;
  overflow: hidden;
}

.exercise-list-header > i {
  color: var(--color-text);
  cursor: pointer;
}
