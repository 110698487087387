.course-landing {
  background-color: #f4f9fe;
  position: relative;

  h2 {
    font-size: 2em;
  }

  &-primary {
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &-content {
    width: 55%;
    padding-bottom: 3em;
    margin-left: 16%;


    & > div {
        padding-bottom: 20px;
        border-bottom: 3px solid #E1EAF3;

        &:last-child {
            border-bottom: 0;
        }
    }

    @media (min-width: 1080px){
        margin-left: 15%;
    }
    
    @media (max-width: 768px){
        width: 100%;
        margin: 0;
    }
  }
}
