.course-stats{
    padding-top: 1.5em;
    
    h2 {
        padding-top: 2em;
        font-size: 1em;
    }
    
    ol {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        li {
            padding: 1em 0;
            display: flex;
            align-items: center;
            flex: 50%;

            @media (max-width: 768px){
                flex: 100%;
            }
    
            img {
                height: 30px;
                margin-right: 2em;
            }

            svg{
                margin: 0 1.5em 0 0.5em;
            }

            span{
                font-size: 18px;
                font-weight: 500;
            }
        }
    }
    
    @media (min-width: 768px) and (max-width: 1000px) {
        li {
            display: flex;
            align-items: center;

            span{
                font-size: 1em !important;
            }
        
            img {
                margin-right: 1em !important;
            }
        }
    }
    
    @media (max-width: 768px) {
        border-top: none;
        padding-top: 0;
    
        h2 {
            padding-top: 1em;
            font-size: 1.5em;
        }
    
        ol {
            padding-bottom: 0;

            li {
                span{
                    font-size: 1em !important;
                }
            }
        }
    }
}