.tagchip {
  display: inline-block;
  font-size: 1em;
  font-weight: 450;
  user-select: none;
  padding: 0.25em 0.5em;
  cursor: pointer;
  border-radius: 2px;
  opacity: 0.8;
  color: whitesmoke;
  background: var(--color-kollin);
  margin-bottom: 8px;
  margin-right: 8px;
}
