.user-menu {
  position: relative;
  z-index: 10;
  padding: 1em 0;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px #ccc;

  &.premium {
    background-color: var(--color-darkblue);

    a,
    i {
      color: var(--color-gold) !important;
    }
  }

  & > div {
    // max-width: 1920px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: space-between;

    a {
      font-size: 1.3em;
      color: #000 !important;
      margin: 0 1em;

      &:hover {
        text-decoration: underline;
      }

      i {
        display: none;
      }
    }

    img {
      max-width: 175px;
      margin-right: 1em;
      cursor: pointer;
    }

    div {
      display: flex;
      align-items: center;
    }
  }

  &-options {
    i {
      font-size: 1.5em;
      color: var(--color-darkgrey);
      cursor: pointer;

      &:hover {
        color: var(--color-kollin);
      }
    }
  }

  &-name {
    margin-left: 2em;
  }
}

.subscribe {
  .ant-modal-body {
    background-color: var(--color-white) !important;
  }
}
