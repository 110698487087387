.course-landing-topic-tag-progress{
    display: flex;
    align-items: center;
    margin: 1.5em 0 0;

    .progress-bar{
        margin-right: 1em !important;
        border-radius: 3px !important;
        height: 12px !important;
        max-width: 80px;

        div{
            border-radius: 3px !important;
        }
    }

    span{
        width: 100%;
        font-size: 16px;
    }
}