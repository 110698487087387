.course-landing-all-reviews {
  margin: 2em 0;
  overflow: scroll;

  h1 {
    font-size: 2em;
    margin: 1em 0;
  }

  @media (max-width: 768px) {
    margin: 4em 2em;
  }

  @media (max-width: 480px) {
    margin: 2em 1em;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
