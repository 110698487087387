.subscription-status-banner {
  width: 100vw;
  padding: 1em;
  background-color: #f4c55055;
  color: #000000;
  text-align: center;

  font-weight: 500;

  a {
    margin-left: 0.5em;
    font-weight: 600;
    text-decoration: underline;
  }
}
