.footer {

  color: #fff;

  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    @media (max-width: 768px) {
      justify-content: center;
    }

    img {
      height: 26px;

    }

    h5 {
      font-size: 30px;
      margin: -2px;
      font-family: "Rubik Mono One";
      margin: 0;
    }
  }

  .social-link {
    color: white;

    &:hover {
      color: var(--color-primary);
    }
  }
}

.footer h5 {
  font-weight: 500;
}

.footer-link span,
.subtitle {
  font-weight: 500;
  font-size: 1.3em;
  color: #fff;
  // margin: 0.5em 0;
}

@media (max-width: 768px) {
  .footer .col-sm-12 {
    padding: 0;
  }
  .footer .row {
    margin: 0;
  }

  .language-picker-container p {
    display: none;
  }
}

.footer-copyright {
  p {
    margin-bottom: 0em;
    margin-top: 0.5em;
  }
}
