.product-purchase-expiry-banner {
  background-color: var(--color-yellow-light);
  width: 100%;
  text-align: center;
  color: var(--color-darkblue);
  padding: 1em;
  font-weight: 600;



  &.success {
    background-color: var(--color-green-light);
  }
}
