.course-landing-topic-preview {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 25;
  overflow-y: scroll;

  &-container {

    display: flex;
    max-width: 80%;
    min-width: 600px;
    margin: 3em auto;
    padding: 3em;
    border-radius: 2em;
    position: relative;
    flex-direction: column;
    cursor: auto;
    position: relative;

    @media (max-width: 768px) {
      padding: 2em;
    }

    @media (max-width: 480px) {
      padding: 1em;
    }
  }

  &-close {
    position: absolute;
    right: 1em;
    color: red;
    font-weight: 600;
    background: #eceffa;
    padding: 0.5em 0.7em;
    border-radius: 2em;
    font-size: 24px;
    cursor: pointer;

    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
}
