.editor {
  color: var(--color-text);
  font-size: 16px;
  font-family: sans-serif;

}

.editor * h3,
h2 {
  color: rgba(0, 0, 0, 0.8);
}

.editor * input {
  padding: 0 !important;
}
