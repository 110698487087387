.course-landing-feature-review-card {
  // margin: 2em 0.2em;
  display: flex;
  flex-direction: column;
  // max-width: 250px;
  width: 100%;
  height: auto;

  // flex: 0 0 30%;
  // height: 100%;

  &-user {
    display: flex;

    .avatar {
      margin-right: 1em;
    }

    &-rate {
      display: flex;
      flex-direction: column;

      .ant-rate {
        font-size: 1em;
      }
    }
  }
}
