.course-landing-title-banner {
  width: 100vw;
  padding: 2em 1em;
  margin-left: 16%;
  display: flex;
  border-bottom: 3px solid #e1eaf3;

  &-name {
    display: flex;
    align-items: center;
    line-height: normal;
    h1 {
      font-size: 36px;
      font-weight: 500;
      letter-spacing: -1px;
      margin-bottom: 0;
      margin-right: 1em;
    }
  }
  h2 {
    font-weight: 400;
    margin: 12px 0;
    font-size: 16px;
  }

  @media (min-width: 1080px) {
    margin-left: 15%;
  }

  @media (min-width: 400px) {
    width: 55%;
  }

  @media (max-width: 768px) {
    padding: 2em 2em 1em;
    margin: 0;
    flex-direction: column;

    h1 {
      font-size: 36px;
      font-weight: 500;
    }
  }

  @media (max-width: 480px) {
    padding: 2em 1em 1em;
  }

  &-icon {
    margin-right: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      display: none;
    }

    img {
      height: 80px;
    }

    .subject-name {
      font-size: 1em;
      font-weight: 600;
      margin-top: 0.5em;
    }
  }

  @media (max-width: 768px) {
    .course-landing-activate-btn {
      display: none;
    }
  }
}
