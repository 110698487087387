.checkout-products-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;

  img {
    max-width: 500px;
  }

  h1 {
    color: var(--color-blue);
  }
}
