.course-landing-difficulty {
  position: absolute;
  right: 1em;
  top: 1.5em;

  @media (max-width: 480px) {
    display: none;
  }

  span {
    padding: 0.5em 1em;
    border-radius: 2em;
  }
}

.course-landing-topic-tag-container {
  padding: 1em;
  width: 100%;
  position: relative;
}

.course-landing-topic-tag {
  display: flex;
  align-items: center;

  &-index {
    display: inline-flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    background-color: #eceffa;
    color: #173286;
    width: 3em;
    min-width: 3em;
    height: 3em;
    font-weight: 900;
    border-radius: 2em;
    margin-right: 1em;
  }

  &-details {
    width: 100%;

    h3 {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
    }

    @media (max-width: 480px) {
      padding: 0.5em;

      h3 {
        font-size: 1.3em;
      }
    }

    &-content {
      display: flex;
      align-items: center;
      margin-top: 1em;

      span {
        margin-right: 1em;
        font-size: 14px;
        font-weight: 500;
      }

      img,
      svg {
        height: 1em;
        margin-right: 0.5em;
      }
    }
  }
}
