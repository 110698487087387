.selection-progress {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
}

.selection-progress > div {
  width: 90%;
}
