.exercise-view-forum {
  padding: 0 1.5em;
  & > div {
    margin-top: 1em !important;
  }

  .thread {
    border-radius: 0;
    box-shadow: none;
  }

  .thread-container.expanded {
    background-color: #cccccc22;
  }
}
