.skeleton {
  width: 100%;
  background-image: linear-gradient(
    100deg,
    rgba(223, 219, 229, 0),
    rgba(223, 219, 229, 0.7) 20%,
    rgba(223, 219, 229, 0) 40%
  );
  background-size: 200%;
  content: "";
  animation: skeleton-animation 1.8s infinite linear;
}

.skeleton,
.skeleton:first-child {
  background-color: rgba(200, 195, 207, 0.2);
}

@keyframes skeleton-animation {
  0% {
    background-position: 100%;
  }

  to {
    background-position: -100%;
  }
}

/** sizes **/

.skeleton.small {
  height: 1em;
}

.skeleton.medium {
  height: 2em;
}
