.lading-page-photo-banner {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 4%;
  margin-bottom: 80px;
}

.lading-page-photo-banner-text {
  padding-top: 100px;
}

.lading-page-photo-banner-text h1 {
  color: var(--color-metalblue);
  font-family: Montserrat;
  font-weight: 600;
  font-size: 4em;
  // font-size: 6vh;
  line-height: 1.3em;
}

.lading-page-photo-banner-text h2 {
  color: var(--color-blue);
  font-family: Montserrat;
  font-weight: 500;
  font-size: 2em;
  // font-size: 3vh;
  line-height: 1.4em;
}

.lading-page-photo-banner-text {
  display: flex;
  flex-direction: column;
}

@media (min-width: 769px) {
  .lading-page-photo-banner {
    /* background-image: url(https://d1mgntrf3vaj6d.cloudfront.net/images/header.svg); */
    // background-image: url(https://d1mgntrf3vaj6d.cloudfront.net/images/Hero+image.jpg);
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
    background-size: 60vw auto;
    background-position: center right -15vh;
  }

  .lading-page-photo-banner img {
    display: none;
  }

  .lading-page-photo-banner-stats {
    margin-top: 150px;
    margin-bottom: 200px;
    background-color: #bdceff73;
    padding: 2em;
    border-radius: 20px;
  }

  .mobile-image {
    display: none;
  }
}

@media (max-width: 768px) {
  .lading-page-photo-banner {
    margin-bottom: 2em;
    padding: 0;
  }

  .lading-page-photo-banner-text {
    width: 100%;
    padding-bottom: 2em;
    padding-top: 3em;
  }

  .lading-page-photo-banner img {
    width: 105%;
  }

  .lading-page-photo-banner-text h1 {
    font-size: 3em;
    color: #313b71;
  }

  .lading-page-photo-banner-text h2 {
    font-size: 2em;
  }

  .lading-page-photo-banner .mobile-image {
    width: 120vw;
  }

  .lading-page-photo-banner .lading-page-photo-banner-stats {
    overflow: visible;
  }

  .lading-page-photo-banner {
    background-size: 0;
  }
}
